@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-300px * 6));
  }
}
.ourBrand-style {
  font-weight: 600;
}

@media only screen and (max-width: 576px) {
  .brand-container {
    margin: 5px;
  }
}
.custom-card {
  margin: 1%;
  height: 180px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-style1 {
  width: 75% !important;
  height: 100px;
  margin: 1% 10%;
}

.image-style2 {
  width: 30% !important;
  margin: 0% 30%;
}
.image-style3 {
  width: 60% !important;
  margin-top: 6%;

}

@media (min-width: 1024px) and (max-width: 1200px) {
  .image-style2 {
    width: 36% !important;
    margin: 0% 30%;
  }
  .image-style3 {
    width: 100% !important;
    margin: 2% 0%;
}
}