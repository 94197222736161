.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a {
  text-decoration: none !important;

  font-family: Helvetica, arial, sans-serif;
}
li {
  list-style: none;
}
p {
  font-size: 20px;
  font-weight: 400;

  font-family: Helvetica, arial, sans-serif;
}
h1 {
  font-family: Helvetica, arial, sans-serif;
}

h2 {
  font-family: Helvetica, arial, sans-serif;
}
h4 {
  font-family: Helvetica, arial, sans-serif;
}
h3 {
  font-family: Helvetica, arial, sans-serif;
}
button {
  font-family: Helvetica, arial, sans-serif;
}
.header-style {
  padding: 4% 0;
  text-align: left;
}
.slick-next {
  right: 0px;
}

/* services card design */

.process-row {
  font-family: Helvetica, arial, sans-serif;
  display: flex;
  justify-content: center;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.traineeship .activity {
  display: flex;
  width: 325px;
  margin: 15px 10px 10px;
  background-color: #242835;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
}
.animate-from-bottom__0 {
  -webkit-animation-name: from-left;
  animation-name: from-left;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.animate-from-bottom__1 {
  -webkit-animation-name: from-left;
  animation-name: from-left;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}
.animate-from-bottom__2 {
  -webkit-animation-name: from-left;
  animation-name: from-left;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.animate-from-bottom__3 {
  -webkit-animation-name: from-left;
  animation-name: from-left;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
}
.animate-from-bottom__4 {
  -webkit-animation-name: from-left;
  animation-name: from-left;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
}
.traineeship .activity .relative-block {
  width: 100%;
  min-height: 250px;
}
@-webkit-keyframes from-left {
  from {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes from-left {
  from {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.relative-block {
  position: relative;
}

.traineeship .inactive {
  padding: 20px;
}
.traineeship .inactive .title {
  padding: 40% 0%;
  border-bottom: 3px solid #f5ca2a;
  padding-bottom: 14px;
  /* width: 210px; */
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.traineeship .activity .active {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  min-height: 100%;
  padding: 25px 30px 50px;
}
.traineeship .activity .active .title {
  color: #1f1f1f;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  line-height: 32px;
}
.traineeship .activity .active .sub-title {
  margin-top: 10px;
  color: #000;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}
.traineeship .activity .active .ul {
  margin-bottom: 45px;
  margin-top: 10px;
}
.traineeship .activity .active .li {
  display: flex;
  margin-left: 30px;
  color: #616161;
  font-size: 16px;
  line-height: 26px;
}
.traineeship .activity .active .li:before {
  position: absolute;
  content: "";
  color: #e2b921;
  font-family: Helvetica, arial, sans-serif;
  display: inline-block;
  margin-left: -1.6em;
  width: 22px;
}
.traineeship .activity:hover {
  width: 558px;
  background-color: #fff;
}
.traineeship .activity:hover .inactive {
  display: none;
}
.traineeship .activity:hover .active {
  display: flex;
  width: 400px;
}
.traineeship .activity:hover .active li {
  font-size: 20px;
  font-weight: 500;
  line-height: 2rem;
}

/* subdropdwon */

.dropdown-submenu-container a.dropdown-submenu::after {
  right: 0px !important;
  top: 1em !important;
  color: #000;
}
/* buttoncss */

.button-style {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01, sans-serif;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}
/* dropdown */
.dropdown-menu.show {
  display: block;
  background: transparent !important;
  border: none;
}
.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center:hover {
  position: relative;
  /* background-image: linear-gradient(to right, rgb(9, 18, 66) 0%, #5472d2 50%, #00c1cf 100%) !important; */
}
@media (min-width: 768px) {
  .button-style {
    margin-top: 10%;
    padding: 19px 32px;
  }
}

.button-style:before,
.button-style:after {
  border-radius: 80px;
}

.button-style:before {
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.button-style:after {
  background-color: initial;
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.button-style:hover:not(:disabled):before {
  background: linear-gradient(
    92.83deg,
    rgb(255, 116, 38) 0%,
    rgb(249, 58, 19) 100%
  );
}

.button-style:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

.button-style:active:not(:disabled) {
  color: #ccc;
}

.button-style:active:not(:disabled):before {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
}

.button-style:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.button-style:disabled {
  cursor: default;
  opacity: 0.24;
}

/* button css */

.button-34 {
  background: #0100a0;
  border-radius: 50px !important;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  outline: 0 solid transparent;
  padding: 8px 18px;
  width: fit-content;
  border: 0;
}
.button-34:hover {
  box-shadow: rgb(1 0 10 / 50%) 0 1px 30px;
  transition-duration: 0.1s;
}
.mark,
mark {
  padding: 0.1875em;
  color: #ffffff !important;
  background-color: #000000 !important;
}
@media (max-width: 576px) {
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    height: 302px;
    /* transform: translate3d(0, 0, 0); */
  }
}

@media only screen and (max-width: 400px) {
  .traineeship .activity .active .sub-title {
    font-size: 0.7em;
  }
}
@media only screen and (max-width: 1000px) {
  .process-row {
    flex-direction: column !important;
  }

  .traineeship .inactive .title {
    font-size: 1.5rem;
    width: max-content;
  }

  .traineeship .activity {
    width: 93%;
  }
  .traineeship .activity .active {
    width: 93%;
  }

  .traineeship .activity .active .sub-title {
    font-size: 1em;
  }

  .traineeship .activity .active .title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .traineeship .activity .relative-block {
    min-height: 100%;
  }

  .traineeship .activity:hover {
    width: 98%;
    margin-left: 2px;
    margin-right: 10px;
  }
}
