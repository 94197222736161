.solutionVirtuozzo-banner {
  background: url(../assets/banners/collaboration1.jpg);
  height: 700px;
  background-size: cover;
}
.virtuozzodecription-image {
  background: url(../assets/virtuozzo-intro.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
@media (max-width: 400px) {
    .solutionVirtuozzo-banner {
      height: 300px;
    }
  
    .solutionstyle-vmware {
      font-size: 20px;
      padding: 60% 14%;
      color: white;
    }
    .virtuozzodecription-image {
      height: 200px;
    }
  }
  @media (min-width: 400px) and (max-width: 576px) {
    .solutionVirtuozzo-banner {
      height: 300px;
    }
    .solutionstyle-vmware {
      font-size: 20px;
      padding: 46% 20%;
      color: white;
    }
    .virtuozzodecription-image {
      height: 280px;
    }
  }
  @media (min-width: 576px) and (max-width: 768px) {
    .solutionBanner-vmware {
      font-size: 24px;
      margin: -30% 6%;
    }
    .solutionVirtuozzo-banner {
      height: 400px;
    }
    .virtuozzodecription-image {
      height: 320px;
    }
    .solutionstyle-vmware {
      font-size: 30px;
      padding: 46% 20%;
      color: white;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .solutionstyle-vmware {
      font-size: 30px;
      padding: 42% 20%;
      color: white;
    }
  
    .solutionVirtuozzo-banner {
      height: 500px;
    }
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    .solutionBanner-vmware {
      margin: -30% 14%;
    }
    .solutionVirtuozzo-banner {
      height: 500px;
    }
  }